import React, { useState, useEffect } from 'react';
import { ArrowUpRight, Search, X, ChevronDown, ChevronUp } from 'lucide-react';

const categories = [
  {
    name: "チャットボット・AI会話",
    icon: "💬",
    services: [
      {
        name: "ChatGPT",
        description: "OpenAIの高度な対話型AI。様々な質問や課題に対応。",
        url: "https://openai.com/chatgpt"
      },
      {
        name: "Claude",
        description: "Anthropicの開発した高性能AI。複雑なタスクや長文の生成、分析が得意。",
        url: "https://www.anthropic.com"
      },
      {
        name: "Chatbase",
        description: "カスタムAIチャットボットを作成するためのプラットフォーム。",
        url: "https://www.chatbase.co/"
      },
      {
        name: "Taskade",
        description: "AIを活用したプロジェクト管理とコラボレーションツール。",
        url: "https://www.taskade.com/"
      },
      {
        name: "ChatBot",
        description: "ビジネス向けAIチャットボット作成プラットフォーム。",
        url: "https://www.chatbot.com/"
      }
    ]
  },
  {
    name: "文章作成・編集",
    icon: "📝",
    services: [
      {
        name: "Grammarly",
        description: "AIを使用した文法チェックと文章改善ツール。",
        url: "https://www.grammarly.com/"
      },
      {
        name: "Jasper",
        description: "AIを活用したコンテンツ作成プラットフォーム。",
        url: "https://www.jasper.ai/"
      },
      {
        name: "Copy.ai",
        description: "マーケティングコピーやコンテンツを生成するAIツール。",
        url: "https://www.copy.ai/"
      },
      {
        name: "Notion AI",
        description: "ノートテイキングとプロジェクト管理にAIを統合したツール。",
        url: "https://www.notion.so/product/ai"
      },
      {
        name: "Rytr",
        description: "AIを使用した文章作成アシスタント。",
        url: "https://rytr.me/"
      }
    ]
  },
  {
    name: "生産性向上・タスク管理",
    icon: "⚡",
    services: [
      {
        name: "Zapier",
        description: "アプリ間の自動化を可能にするAIプラットフォーム。",
        url: "https://zapier.com/"
      },
      {
        name: "Todoist",
        description: "AIを活用したタスク管理アプリ。",
        url: "https://todoist.com/"
      },
      {
        name: "Notion",
        description: "AIを統合したオールインワンのワークスペース。",
        url: "https://www.notion.so/"
      },
      {
        name: "Cogram",
        description: "AIを活用したミーティングノート作成ツール。",
        url: "https://www.cogram.com/"
      },
      {
        name: "Mem",
        description: "AIパワードの個人的ナレッジベース。",
        url: "https://mem.ai/"
      }
    ]
  },
  {
    name: "マーケティング・分析",
    icon: "📊",
    services: [
      {
        name: "AdCreative.ai",
        description: "AIを使用した広告クリエイティブ生成ツール。",
        url: "https://www.adcreative.ai/"
      },
      {
        name: "Lately",
        description: "AIを活用したソーシャルメディアマーケティングプラットフォーム。",
        url: "https://www.lately.ai/"
      },
      {
        name: "Semrush",
        description: "AIを統合したオンラインマーケティングツールスイート。",
        url: "https://www.semrush.com/"
      },
      {
        name: "Jasper",
        description: "AIを活用したコンテンツ作成プラットフォーム。",
        url: "https://www.jasper.ai/"
      },
      {
        name: "Markopolo",
        description: "AIを使用したデジタル広告最適化プラットフォーム。",
        url: "https://www.markopolo.ai/"
      }
    ]
  },
  {
    name: "画像生成・編集",
    icon: "🎨",
    services: [
      {
        name: "DALL-E",
        description: "OpenAIのテキストから画像を生成するAI。",
        url: "https://openai.com/dall-e-2/"
      },
      {
        name: "Midjourney",
        description: "高品質なAI画像生成ツール。",
        url: "https://www.midjourney.com/"
      },
      {
        name: "Stable Diffusion",
        description: "オープンソースの画像生成AI。",
        url: "https://stability.ai/"
      },
      {
        name: "Canva",
        description: "AIを活用したグラフィックデザインプラットフォーム。",
        url: "https://www.canva.com/"
      },
      {
        name: "Playground AI",
        description: "AIを使用した画像編集・生成ツール。",
        url: "https://playground.ai/"
      }
    ]
  },
  {
    name: "動画・音楽制作",
    icon: "🎵",
    services: [
      {
        name: "Descript",
        description: "AIを活用した動画・音声編集ツール。",
        url: "https://www.descript.com/"
      },
      {
        name: "Runway",
        description: "AIを使用したビデオ編集と視覚効果ツール。",
        url: "https://runwayml.com/"
      },
      {
        name: "Soundraw",
        description: "AIを使用した音楽生成プラットフォーム。",
        url: "https://soundraw.io/"
      },
      {
        name: "Synthesia",
        description: "AIを使用したビデオ生成プラットフォーム。",
        url: "https://www.synthesia.io/"
      },
      {
        name: "Mubert",
        description: "AIを使用した音楽生成サービス。",
        url: "https://mubert.com/"
      }
    ]
  }
];

const AIServicesSPA = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState(categories);

  useEffect(() => {
    const results = categories.map(category => ({
      ...category,
      services: category.services.filter(service => 
        service.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.description.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })).filter(category => category.services.length > 0);
    
    setFilteredCategories(results);
  }, [searchTerm]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl sm:text-5xl font-extrabold text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 mb-12">
          AI サービス 検索
        </h1>
        
        <div className="mb-8 relative">
          <input
            type="text"
            placeholder="AIサービスを検索..."
            className="w-full p-4 pr-12 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="absolute right-4 top-4 text-gray-400 hover:text-gray-600">
            {searchTerm ? (
              <X className="w-6 h-6" onClick={() => setSearchTerm('')} />
            ) : (
              <Search className="w-6 h-6" />
            )}
          </button>
        </div>
        
        <div className="space-y-6">
          {filteredCategories.map((category, index) => (
            <div 
              key={index} 
              className={`rounded-2xl shadow-lg overflow-hidden transition-all duration-300 ${activeCategory === index ? 'bg-white' : 'bg-gradient-to-r from-indigo-600 to-purple-600'}`}
            >
              <div 
                className={`px-6 py-4 cursor-pointer flex justify-between items-center ${activeCategory === index ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white' : 'text-white'}`}
                onClick={() => setActiveCategory(activeCategory === index ? null : index)}
              >
                <h2 className="text-xl sm:text-2xl font-semibold flex items-center">
                  <span className="mr-3 text-2xl sm:text-3xl">{category.icon}</span>
                  {category.name}
                </h2>
                {activeCategory === index ? <ChevronUp className="w-6 h-6" /> : <ChevronDown className="w-6 h-6" />}
              </div>
              {activeCategory === index && (
                <div className="mt-4 space-y-4 p-6 bg-white">
                  {category.services.map((service, sIndex) => (
                    <div key={sIndex} className="border-b border-gray-200 pb-4 last:border-b-0 last:pb-0">
                      <a 
                        href={service.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-800 font-medium flex items-center text-lg sm:text-xl mb-2 transition-colors duration-200"
                      >
                        {service.name}
                        <ArrowUpRight className="ml-2 w-5 h-5" />
                      </a>
                      <p className="text-gray-600 leading-relaxed">{service.description}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AIServicesSPA;